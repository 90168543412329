import $ from 'jquery';
import { isExit, libNodes } from './Utils';

const $openMenu = $('.js_open_menu');
const $headerMenu = $('.header_menu');
const $closeMenu = $('.js_close_menu');

const init = () => {
    $openMenu.on('click', onShow);

    $closeMenu.on('click', onClose);
};

function onShow() {
    $openMenu.addClass('btn--menu_active');
    $headerMenu.fadeIn(700);

    libNodes.$html.addClass('header_menu_opened');
    libNodes.$header.css({ 'z-index': '1001' });

    $(document).on('keydown', (e) => {
        if (isExit(e.keyCode)) {
            onClose();
        }
    });
}

function onClose() {
    $openMenu.removeClass('btn--menu_active');
    $headerMenu.fadeOut(700, () => {
        libNodes.$html.removeClass('header_menu_opened');
        libNodes.$header.css({ 'z-index': '' });
    });

    $(document).off('keydown');
}

export default init();
