import IMask from 'imask';

window.addEventListener('load', () => {
    function initPhoneMask() {
        const phoneInputs = document.querySelectorAll('.js-input-phone');
        const maskOptions = {
            mask: [
                {
                    mask: '+{7} (000) 000-00-00'
                }
            ]
        };

        phoneInputs.forEach((input) => {
            IMask(input, maskOptions);
        });
    }

    initPhoneMask();
});
