import $ from 'jquery';

const $textTables = $('.text table');

/**
 * @description Рендерит пункт навигационного меню по контенту страницы
 *
 * @param {Number} id id H2 элемента
 * @param {String} title Текст, размещаемый внутри ссылки
 */
const renderNavPageItem = (id, title) => `
    <li class="nav_page__item">
        <a class="nav_page__link js_anchor js_hover_link" href="#${id}">${title}</a>
    </li>
`;

const $scrollSpyContainer = $('[data-scrollspy]');

const init = () => {
    $textTables.each(function() {
        $(this).wrap('<div class="table_wrap">');
    });

    $('.layout--v6 h2').each(function(index) {
        this.id = `block_${index}`;

        $scrollSpyContainer.append(
            renderNavPageItem(`block_${index}`, this.innerText)
        );
    });
};

export default init();
