import TurboForm from '../lib/TurboForm';
import '../lib/jquery.ui.widget';

var forms = document.querySelectorAll('.js-form');
if (forms.length > 0) {
    forms.forEach(function(el) {
        const form = new TurboForm(el, {
            errorClass: 'form__group--error',
            fieldLineClass: 'form__group',
            errorMsgClass: 'form__error_message',
            showErrorAfterInput: true
        });

        const modal = form.form.closest('.feedback_modal');
        const submitButton = form.form.querySelector('.form__send');
        const successMessage = form.form
            .closest('.form_wrapper')
            .querySelector('.js-success-message');

        const successAccept = successMessage.querySelector(
            '.sending_results__accept'
        );

        const succesAppearAnimationEnd = () => {
            successMessage.classList.remove(
                'sending_results--appear',
                'sending_results--hidden'
            );

            successMessage.removeEventListener(
                'animationend',
                succesAppearAnimationEnd
            );
        };

        const formDisappearAnimationEnd = () => {
            successMessage.classList.add(
                'sending_results--visible',
                'sending_results--appear'
            );
            successMessage.addEventListener(
                'animationend',
                succesAppearAnimationEnd
            );

            form.form.classList.add('form--hidden');
            form.form.classList.remove('form--disappear');
            form.form.removeEventListener(
                'animationend',
                formDisappearAnimationEnd
            );
        };

        if (successAccept) {
            successAccept.addEventListener('click', () => {
                successMessage.classList.remove('sending_results--visible');
                successMessage.classList.add('sending_results--hidden');

                form.form.classList.remove('form--hidden');
                form.form.classList.add('form--visible');
            });
        }

        form.validate = function() {
            TurboForm.prototype.validate.apply(this);

            if (form.isValidate) {
                submitButton.classList.remove('btn--disabled');
            } else {
                submitButton.classList.add('btn--disabled');
            }
        };

        form.form.addEventListener('input', function() {
            form.validate();
        });

        submitButton.addEventListener('click', (e) => {
            e.preventDefault();
            form.hideOldErrors();
            form.onSubmit();

            if (successMessage.classList.contains('sending_results--visible')) {
                successMessage.classList.remove('sending_results--visible');
                successMessage.classList.add('sending_results--hidden');
            }
        });

        form.showPreloader = function() {
            this.form.classList.add('form--sending');

            if (modal) {
                modal.classList.add('feedback_modal--sending');
            }
        };

        form.hidePreloader = function() {
            this.form.classList.remove('form--sending');

            if (modal) {
                modal.classList.remove('feedback_modal--sending');
            }
        };

        form.reset = function() {
            TurboForm.prototype.reset.apply(this);

            submitButton.classList.add('btn--disabled');
        };

        form.showSuccessMessage = function(msg) {
            document.querySelector(
                `.js-success-message__${this.form.getAttribute('name')}`
            ).innerHTML = msg;

            this.reset();

            if (this.form.getAttribute('name') === 'callback') {
                this.form.classList.add('form--disappear');
                this.form.classList.remove('form--visible');

                this.form.addEventListener(
                    'animationend',
                    formDisappearAnimationEnd
                );
            } else {
                successMessage.classList.add(
                    'sending_results--visible',
                    'sending_results--appear'
                );
                successMessage.addEventListener(
                    'animationend',
                    succesAppearAnimationEnd
                );
            }
        };

        form.showErrorForInput = function(container, input, filedName) {
            TurboForm.prototype.showErrorForInput.apply(this, [
                container,
                input,
                filedName
            ]);
            container.classList.add('form__group--error');
        };

        form.hideOldErrors = function() {
            TurboForm.prototype.hideOldErrors.apply(this);
            var items = this.form.querySelectorAll('.form__group--error');
            if (items.length) {
                items.forEach(function(field) {
                    field.classList.remove('form__group--error');
                });
            }
        };

        form.validate();
    });
}
