const inputs = document.querySelectorAll('input, textarea');

const init = () => {
    Array.prototype.filter
        .call(
            inputs,
            (input) =>
                input.type !== 'checkbox' &&
                input.type !== 'radio' &&
                input.type !== 'hidden'
        )
        .forEach(function(input) {
            if (input.value.length) {
                input.parentElement.classList.add('form__group--not_empty');
            }

            input.addEventListener('change', () => {
                if (input.value !== '') {
                    input.parentElement.classList.add('form__group--not_empty');
                } else {
                    input.parentElement.classList.remove(
                        'form__group--not_empty'
                    );
                }
            });
        });
};

export default init();
