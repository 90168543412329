import $ from 'jquery';
import { libNodes } from './Utils';

const $anchorLinks = $('.js_anchor');

const init = () => {
    $anchorLinks.on('click', function(e) {
        e.preventDefault();

        const target = document.querySelector(`${this.hash}`);

        if (target) {
            libNodes.$html.addClass('scroll_animated');

            libNodes.$body.stop().animate(
                {
                    scrollTop: target.offsetTop
                },
                800,
                'swing',
                () => libNodes.$html.removeClass('scroll_animated')
            );
        }
    });
};

export default init();
