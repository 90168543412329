import $ from 'jquery';
import 'lightgallery';
import 'lightgallery/modules/lg-thumbnail';
import 'lightgallery/modules/lg-zoom';

const $gallery = $('.js_gallery');

const init = () => {
    $gallery.lightGallery({
        selector: '.gallery__item',
        download: true,
        thumbnail: true,
        zoom: true
    });
};

export default init();
