const demoLink = document.querySelector('.demo');

const init = () => {
    if (demoLink) {
        let iframe = document.createElement('iframe');
        let YouTubeAPI = document.createElement('script');
        let initYouTubeAPI = document.createElement('script');
        const modalVideo = document.querySelector('.video_modal__video');

        YouTubeAPI.src = 'https://www.youtube.com/player_api';

        initYouTubeAPI.innerText =
            'let player = null; function onYouTubePlayerAPIReady() { player = new YT.Player("video"); } document.documentElement.addEventListener("modalclosed", function() { player.pauseVideo(); } );';

        iframe.src = demoLink.href;
        iframe.allowFullscreen = true;
        iframe.id = 'video';

        modalVideo.appendChild(iframe);
        modalVideo.appendChild(YouTubeAPI);
        modalVideo.appendChild(initYouTubeAPI);
    }
};

export default init();
