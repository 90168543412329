const selects = document.querySelectorAll('.select__field');

const init = () => {
    selects.forEach((select) => {
        if (select.options[select.selectedIndex].disabled) {
            select.classList.add('select__field--empty');
        }

        select.addEventListener('change', () => {
            select.classList.remove('select__field--empty');
        });
    });
};

export default init();
