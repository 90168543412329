import throttle from 'lodash.throttle';
import { getScrollbarWidth, libNodes } from './Utils';

const scrollbarWidth = getScrollbarWidth();
let headerHeight = libNodes.$header.outerHeight();
let footerHeight = libNodes.$footer.outerHeight();

const onResize = throttle(() => {
    headerHeight = libNodes.$header.outerHeight();
    footerHeight = libNodes.$footer.outerHeight();

    init();
}, 1000);

window.addEventListener('resize', onResize);

const init = () => {
    libNodes.$html.css({
        '--scrollbar_width': `${scrollbarWidth}px`,
        '--header_height': `${headerHeight}px`,
        '--footer_height': `${footerHeight}px`
    });
};

export default init();
