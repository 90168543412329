import { isMobileSystem } from './Utils';
import throttle from 'lodash.throttle';

const links = document.querySelectorAll('.js_hover_link');
const div = document.createElement('div');

div.classList.add('hover_link');

const updateCircle = ({ wrapper, pointerCoords }) => {
    let offset = wrapper.getBoundingClientRect();

    div.style.left = `${Math.floor(pointerCoords.x - offset.left) -
        div.clientWidth / 2}px`;
    div.style.top = `${Math.floor(pointerCoords.y - offset.top) -
        div.clientHeight / 2}px`;
};

const resetCircle = () => {
    div.style.left = null;
    div.style.top = null;
};

const onMouseMove = throttle((e) => {
    updateCircle({
        wrapper: e.target,
        pointerCoords: { x: e.clientX, y: e.clientY }
    });

    if (document.querySelector('.hover_link') === null) {
        resetCircle();
    }
}, 50);

const onEnterAnimationEnd = () => {
    div.classList.remove('hover_link--appear');

    div.removeEventListener('animationend', onEnterAnimationEnd);
};

const onLeaveAnimationEnd = (e) => {
    div.classList.remove('hover_link--disappear');
    e.target.parentElement.removeChild(div);

    resetCircle();
    div.removeEventListener('animationend', onLeaveAnimationEnd);
};

const init = () => {
    if (!isMobileSystem()) {
        links.forEach(function(link) {
            link.addEventListener('mouseenter', function(e) {
                resetCircle();

                this.appendChild(div);

                div.removeEventListener('animationend', onLeaveAnimationEnd);
                div.classList.remove('hover_link--disappear');
                div.classList.add('hover_link--appear');
                div.addEventListener('animationend', onEnterAnimationEnd);

                updateCircle({
                    wrapper: this,
                    pointerCoords: { x: e.clientX, y: e.clientY }
                });

                document.addEventListener('mousemove', onMouseMove);
            });

            link.addEventListener('mouseleave', function() {
                div.classList.add('hover_link--disappear');
                div.addEventListener('animationend', onLeaveAnimationEnd);

                document.removeEventListener('mousemove', onMouseMove);
            });
        });
    }
};

export default init();
