import throttle from 'lodash.throttle';
import { libNodes } from './Utils';

const asideContents = document.querySelectorAll('.aside__inner');

const onScroll = throttle(() => {
    asideContents.forEach((asideContent) => {
        if (
            asideContent.offsetTop +
                asideContent.offsetHeight +
                libNodes.$header[0].offsetHeight >=
            document.documentElement.offsetHeight -
                libNodes.$footer[0].offsetHeight -
                30
        ) {
            asideContent.style.transform = 'translateY(0)';
        } else {
            asideContent.style.transform = '';
        }
    });
}, 50);

const init = () => {
    // window.addEventListener('scroll', onScroll);
};

export default init();
