import {
    Swiper,
    Pagination,
    EffectFade,
    Navigation,
    Autoplay
} from 'swiper/dist/js/swiper.esm';

Swiper.use([Pagination, EffectFade, Navigation, Autoplay]);

const onInit = function() {
    this.el.parentElement.querySelector('.slider__num_end').innerText = `${
        this.slides.length
    }`;
};

const onPaginationUpdate = function() {
    const indicator = this.el.parentElement.querySelector('.slider__indicator');

    const bulletWidth = this.el.parentElement.querySelector(
        '.swiper-pagination-bullet'
    ).clientWidth;

    const offsetLeft = this.el.parentElement.querySelector(
        '.swiper-pagination-bullet-active'
    ).offsetLeft;

    indicator.style.width = `${bulletWidth}px`;
    indicator.style.transform = `translateX(${offsetLeft}px)`;
};

const mainSlider = () => {
    return new Swiper('.js_slider', {
        autoplay: {
            delay: 5000
        },
        speed: 1000,
        spaceBetween: 200,
        grabCursor: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        fadeEffect: {
            crossFade: true
        },
        effect: 'fade',
        on: {
            init: onInit,
            resize: onPaginationUpdate,
            paginationUpdate: onPaginationUpdate
        }
    });
};

const initPhotoSlider = () => {
    new Swiper('.js_photo_slider', {
        speed: 1000,
        spaceBetween: 50,
        grabCursor: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next'
        },
        on: {
            init: onInit,
            resize: onPaginationUpdate,
            paginationUpdate: onPaginationUpdate
        }
    });
};

const init = () => {
    mainSlider();
    initPhotoSlider();
};

export default init();
