import throttle from 'lodash.throttle';
import { libNodes } from './Utils';

let lastPosition =
    document.documentElement.scrollTop || document.body.scrollTop;
const deltaTrigger = 0;

const onScroll = throttle(() => {
    let currentPosition =
        document.documentElement.scrollTop || document.body.scrollTop;

    if (Math.abs(currentPosition - lastPosition) < deltaTrigger) {
        return;
    }

    if (currentPosition >= 50) {
        if (!libNodes.$html.hasClass('header_menu_opened')) {
            libNodes.$header[0].classList.add('header--hidden');
            libNodes.$html[0].classList.add('header_flow');

            if (currentPosition < lastPosition) {
                libNodes.$header[0].classList.remove('header--hidden');
                libNodes.$header[0].classList.add('header--flow');
                libNodes.$html[0].classList.remove('header_flow');
            } else {
                libNodes.$header[0].classList.add('header--hidden');
                libNodes.$html[0].classList.add('header_flow');
            }
        }
    } else {
        libNodes.$header[0].classList.remove('header--hidden');
        libNodes.$header[0].classList.remove('header--flow');
        libNodes.$html[0].classList.remove('header_flow');
    }

    lastPosition = currentPosition;
}, 100);

const init = () => {
    window.addEventListener('scroll', onScroll);

    onScroll();
};

export default init();
