import $ from 'jquery';

const $accordionButtons = $('.js_acc');

const init = () => {
    $accordionButtons.on('click', function() {
        $(this)
            .toggleClass('faq__question--expanded')
            .next()
            .slideToggle(500);
    });
};

export default init();
