import throttle from 'lodash.throttle';
import { libNodes, isInViewPort, isInTopViewPort } from './Utils';

const scrollspyWrapper = document.querySelector('[data-scrollspy]');
let indicator = null;
let spyLinks = null;
let spyTargets = null;

if (scrollspyWrapper) {
    indicator = scrollspyWrapper.querySelector('[data-scrollspy-indicator]');
    spyLinks = scrollspyWrapper.querySelectorAll('a');
    spyTargets = Array.prototype.map.call(spyLinks, (spyLink) =>
        document.querySelector(`${spyLink.hash}`)
    );
}

const updateIndicator = (spyLink) => {
    indicator.style.height = `${spyLink.offsetHeight}px`;
    indicator.style.transform = `translateY(${
        spyLink.parentElement.offsetTop
    }px)`;
};

const activateScrollspyLink = (target) => {
    indicator.style.display = 'block';

    const firstSpylink = scrollspyWrapper.querySelector(
        `a[href$="#${target.id}"]`
    );

    if (!firstSpylink.dataset.scrollspyActive) {
        document.querySelectorAll('[data-scrollspy] a').forEach((item) => {
            delete item.dataset.scrollspyActive;
        });

        firstSpylink.dataset.scrollspyActive = '';
        updateIndicator(firstSpylink);
    }
};

const onScroll = () => {
    const onScreen = spyTargets.filter((spyTarget) => isInViewPort(spyTarget));

    if (onScreen.length) {
        activateScrollspyLink(onScreen[0]);
    } else {
        const onTop = spyTargets.filter((spyTarget) =>
            isInTopViewPort(spyTarget)
        );

        if (onTop.length) {
            activateScrollspyLink(onTop[onTop.length - 1]);
        } else {
            scrollspyWrapper
                .querySelectorAll('[data-scrollspy] a')
                .forEach((item) => {
                    delete item.dataset.scrollspyActive;
                });

            indicator.style.display = 'none';
        }
    }
};

const init = () => {
    if (scrollspyWrapper) {
        onScroll();

        window.addEventListener('scroll', throttle(onScroll, 50));
    }
};

export default init();
