import $ from 'jquery';
import { isExit, libNodes, customEvents } from './Utils';

const $fadeInButton = $('.js_fade_in');
const $fadeOutButton = $('.js_fade_out');

const init = () => {
    $fadeInButton.on('click', function(e) {
        e.preventDefault();

        const $target = $(`[data-name="${this.dataset.target}"]`);

        show($target);
    });

    $fadeOutButton.on('click', function(e) {
        e.preventDefault();

        const $target = $(`[data-name="${this.dataset.target}"]`);

        close($target);
    });
};

function show($element) {
    libNodes.$html.addClass('modal_opened');
    $element.fadeIn(500);

    $(document).on('keydown', (e) => {
        if (isExit(e.keyCode)) {
            close($element);
        }
    });
}

function close($element) {
    document.documentElement.dispatchEvent(customEvents.modalClosed);

    $element.fadeOut(500, () => {
        libNodes.$html.removeClass('modal_opened');
    });

    $(document).off('keydown');
}

export default init();
