import $ from 'jquery';

/**
 * Функция определяет ширину полосы прокрутки (скроллбара)
 *
 * @returns {Number} Ширина полосы прокрутки
 */
const getScrollbarWidth = () => {
    const div = document.createElement('div');

    div.style.width = '50px';
    div.style.height = '50px';
    div.style.overflowY = 'scroll';
    div.style.visibility = 'hidden';
    div.style.position = 'absolute';

    document.body.appendChild(div);

    const scrollWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);

    return scrollWidth;
};

/**
 * Функция определяет текущую мобильную операционную систему устройства
 *
 * @returns {String} Возвращает текущую операционную систему
 */
const getMobileOperatingSystem = () => {
    let userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    if (/ipad|iphone|ipod/i.test(userAgent) && !window.MSStream) {
        return 'iOS';
    }
};

/**
 *
 * @returns {Boolean} Возвращает true, если текущая операционная система мобильная, иначе false
 */
const isMobileSystem = () =>
    getMobileOperatingSystem() === 'Android' ||
    getMobileOperatingSystem() === 'iOS';

const escapeKeyCode = 27;
/**
 * Функция проверяет нажатие клавиши Esc
 *
 * @param {Number} keyCode Код клавиши для проверки
 */
const isExit = (keyCode) => keyCode === escapeKeyCode;

/**
 * @returns {Object} Коллекция jQuery HTMLElement
 */
const libNodes = (() => {
    return {
        $html: $('html'),
        $header: $('.header'),
        $footer: $('.footer'),
        $body: $('html, body')
    };
})();

/**
 * Объект с кастомными событиями
 */
const customEvents = {
    modalClosed: new CustomEvent('modalclosed')
};

/**
 *
 * @param {HTMLElement} element
 */
const isInViewPort = (element) => {
    const rect = element.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
    );
};

const isInTopViewPort = (element) => {
    const rect = element.getBoundingClientRect();

    return rect.top < 200;
};

export {
    getScrollbarWidth,
    isExit,
    libNodes,
    getMobileOperatingSystem,
    isMobileSystem,
    customEvents,
    isInViewPort,
    isInTopViewPort
};
