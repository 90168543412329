import $ from 'jquery';
import { libNodes } from './Utils';

// const $maps = $('.js_map');
const $openMap = $('.js_open_map');
const $closeMap = $('.js_close_map');
const $mapWrap = $('.map_wrapper');

// const initMap = () => {
//     if ($maps.length) {
//         ymaps.ready(() => {
//             $maps.each(function() {
//                 let myMap = new ymaps.Map(
//                         this,
//                         {
//                             center: [56.019108, 92.893364],
//                             zoom: 14,
//                             controls: [
//                                 'zoomControl',
//                                 'searchControl',
//                                 'typeSelector',
//                                 'fullscreenControl',
//                                 'trafficControl',
//                                 'geolocationControl'
//                             ]
//                         },
//                         {
//                             searchControlProvider: 'yandex#search'
//                         }
//                     ),
//                     myPlacemark_1 = new ymaps.Placemark(
//                         [56.019108, 92.893364],
//                         {
//                             balloonContent:
//                                 'г. Красноярск, ул. Дубенского 4, офис 237'
//                         }
//                     );

//                 myMap.controls.each(function(control) {
//                     control.options.set('float', 'right');
//                 });

//                 myMap.geoObjects.add(myPlacemark_1);
//             });
//         });
//     }
// };

const init = () => {
    // Инициализация карты на моей стороне не нужна, но я ее оставлю, дабы тащить из проекта в проект. Обработка открытия/закрытия карты все еще остается.
    // initMap();

    $openMap.on('click', function(e) {
        e.preventDefault();

        $mapWrap.addClass('map_wrapper--visible');
        libNodes.$html.addClass(['map_opened', 'modal_opened']);
    });

    $closeMap.on('click', function() {
        $mapWrap.removeClass('map_wrapper--visible');
        libNodes.$html.removeClass(['map_opened', 'modal_opened']);
    });
};

export default init();
