import $ from 'jquery';

const toggleList = (e) => {
    const target = e.target.closest('.js-toggle-list-button');

    if (target) {
        const listItem = target.closest('.js-toggle-list-item');
        const listHiddenContent = listItem.querySelector(
            '.js-toggle-list-hidden'
        );
        $(listHiddenContent).slideToggle(300);
        rotateIcon(listItem);
    }
};

const rotateIcon = (listItem) => {
    const icon = listItem.querySelector('.js-toggle-list-icon');
    icon.classList.toggle('active');
};

document.addEventListener('click', toggleList);
