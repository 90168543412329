import $ from 'jquery';
import throttle from 'lodash.throttle';

const $tabs = $('.js_tab');
const $tabContents = $('.js_tab_content');
const offset = 20;

const onResize = throttle(() => {
    $tabs.siblings('.tabs__tab--active').each(function() {
        $(this)
            .siblings('.js_tab_indicator')
            .css({
                width: `${$(this).outerWidth() - offset}px`,
                transform: `translateX(${this.offsetLeft}px)`
            });
    });
}, 1000);

const init = () => {
    window.addEventListener('resize', onResize);

    $tabs.on('click', function(e) {
        e.preventDefault();

        const $this = $(this);
        const $tabIndicator = $this.siblings('.js_tab_indicator');
        const $target = $tabContents.children(this.hash);

        $target
            .siblings('.tabs__content--active')
            .fadeOut(300, () => {
                $target.fadeIn(300).addClass('tabs__content--active');
            })
            .removeClass('tabs__content--active');

        $this
            .addClass('tabs__tab--active')
            .siblings()
            .removeClass('tabs__tab--active');

        $tabIndicator.css({
            width: `${$this.outerWidth() - offset}px`,
            transform: `translateX(${this.offsetLeft}px)`
        });
    });

    $tabs.filter('.tabs__tab--active').trigger('click');
};

export default init();
